<template>
  <div :id="$route.name" class="bg-black-950">
    <Header v-if="showHeaderAndFooter" class="px-0" />
    <div
      v-if="!dataAreLoaded"
      class="bg-black-600 h-screen w-full animate-pulse blur-md"
    >
      &nbsp;
    </div>
    <main
      class="h-full"
      data-scroll
      :class="{
        hidden: !dataAreLoaded,
      }"
    >
      <slot :key="$route.fullPath" />
    </main>
    <Footer v-if="showHeaderAndFooter" />
    <ClientOnly>
      <Banner v-bind="cookieBanner" />
      <Banner
        v-if="promotionBanner && $route.path === '/'"
        v-bind="promotionBanner"
      />
    </ClientOnly>
    <div
      v-if="route.fullPath !== '/schedule-call' && showHeaderAndFooter"
      class="bg-mango fixed bottom-4 right-4 z-20 flex h-max w-max cursor-pointer items-center justify-center overflow-hidden rounded-full text-white sm:hidden"
      @click="contactButtonAction(shouldExpandContactButton)"
    >
      <div
        class="m-0.5 flex h-14 select-none items-center justify-center rounded-full bg-black p-5 transition-all ease-in-out"
        :class="{
          'w-14': !shouldExpandContactButton,
          'w-52': shouldExpandContactButton,
        }"
      >
        <Icon name="call" size="large" />
        <p
          v-if="shouldExpandContactButton"
          class="font-mierb text-capitalSmall whitespace-nowrap pl-2 font-semibold uppercase leading-5 tracking-wider text-white"
        >
          Schedule a call
        </p>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useRoute as vUseRoute, useRouter as vUserRouter } from "vue-router";
import {
  onBeforeMount as vOnBeforeMount,
  ref as vRef,
  computed as vComputed,
} from "vue";
import { Banner as BannerType } from "~/components/Banner/types";
import { trackEvent } from "~/utils/gtag";
import { Actions } from "~/types/gtag";
import Icon from "~/components/Icon/Icon.vue";

const { findOne } = useStrapi4();
const cookieBanner = vRef<BannerType>();
const promotionBanner = vRef<BannerType>();
const route = vUseRoute();
const nuxtApp = useNuxtApp();
const dataAreLoaded = vRef(false);
const shouldExpandContactButton = vRef(false);
const { fetch } = useCmsifiedPageRendered();
const { data } = await useAsyncData(`page-${route.params.slug}`, () => fetch());

// This function is designed to hide the header and footer when the page is flagged as a Campaign.
const showHeaderAndFooter = vComputed<boolean>(() => {
  if (data?.value?.flagCampaign) {
    useHead({
      meta: [{ name: "robots", content: "noindex, nofollow" }],
    });
    return false;
  } else {
    return true;
  }
});

nuxtApp.hook("page:start", () => {
  dataAreLoaded.value = false;
});

nuxtApp.hook("page:finish", () => {
  dataAreLoaded.value = true;
  if (!shouldNotTrackPage.value) {
    trackEvent({
      name: Actions.PAGE_DETAILS,
      campaignAttributes: true,
      video: undefined,
      contentTypeData: undefined,
      path: route.fullPath,
    });
  }
});

const shouldNotTrackPage = vComputed<Boolean>(() => {
  return route?.name?.toString()
    ? [
        "resources-newsletters-slug",
        "resources-presentations-slug",
        "product-spotlights-slug",
        "resources-webinars-slug",
        "articles-slug",
        "scenarios-slug",
        "resources-webinars-slug",
        "events-slug",
      ].includes(route?.name?.toString())
    : false;
});

vOnBeforeMount(async () => {
  const { data: banners } = await findOne<BannerType[]>("banners", {
    populate: "*",
    filters: {
      type: ["CookieBanner", "PromotionBanner"],
    },
  });
  if (banners.length) {
    banners.forEach((banner) => {
      switch (banner.attributes.type) {
        case "CookieBanner":
          cookieBanner.value = banner;
          break;
        case "PromotionBanner":
          promotionBanner.value = banner;
          break;
      }
    });
  }
});
const contactButtonAction = (currentValue: boolean) => {
  if (currentValue) {
    shouldExpandContactButton.value = false;
    navigateTo("/schedule-call");
  } else {
    shouldExpandContactButton.value = true;
  }
};
</script>
